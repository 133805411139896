<!-- <html>
    <head>
        <title>USC Films</title>
        <link href="./homepage.component.css" rel="stylesheet" type="text/css">
    </head>

    <body> -->
<div class="container">

    <div class="row">
        <div class="carousel_div text-center">
            <!-- <div *ngIf = "posts"> -->
                <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)" >
                    <ng-template ngbSlide *ngFor="let img of images; index as i">
                        <!-- <div>{{img}}</div> -->
                        
                        <a routerLink="./watch/movie/{{ids[i]}}" rel="nofollow noopener noreferrer">
                        <div class="picsum-img-wrapper img-con text-center">
                            <div class="carousel-caption" style="width: 88%;">
                                <p>{{titles[i]}}</p>
                            </div>
                            <img [src]="img" alt="{{titles[i]}}">
                        </div>
                        </a>
                    </ng-template>
                </ngb-carousel>
            <!-- </div> -->
        </div>

    </div>

    <div class="row" *ngIf="!isEmpty" style="display: none;">
        <div class="container conti">
            <div class="row sect">
                <h1>Continue Watching</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" data-interval="false">

                    <ng-template ngbSlide *ngFor="let CGroup of Cids; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of CGroup; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    <!-- <div class="picsum-img-wrapper align-self-bottom text-bottom"> -->
                                        <a routerLink="./watch/{{Cmedia[i][j]}}/{{game}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{Cposter[i][j]}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{Ctitle[i][j]}}</p>
                                        </div></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                
                </ngb-carousel>       
            </div>
        </div>
        
    </div>


    <div class="row">
        <div class="container">
            <div class="sect row">
                <h1> Popular Movies</h1>
            </div>

            <div class="text-center row" *ngIf="post">
                <!-- Mobile section : one image per slide -->
                <ngb-carousel *ngIf="mobile">
                    <ng-template ngbSlide *ngFor="let image of games">
                        <div class="" style="">
                            <div class="col-xs-3 col-md-3 col-lg-3 col-sm-6">
                                <img class="" src="{{image.title_img}}">
                            </div>
                        </div>
                    </ng-template>
                </ngb-carousel>

                <!-- Desktop section : multiple images per slide -->
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of moviesFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    <!-- <div class="picsum-img-wrapper align-self-bottom text-bottom"> -->
                                        <a routerLink="./watch/movie/{{movieIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{movieTitles[i][j]}}</p>
                                        </div></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
    </div>

    <br><br>

    <div class="row" *ngIf="topMovie">
        <div class="container">
            <div class="row sect">
                <h1>Top Rated Movies</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of topMovieFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    <!-- <div class="picsum-img-wrapper align-self-bottom text-bottom"> -->
                                        <a routerLink="./watch/movie/{{topMovieIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{topMovieTitles[i][j]}}</p>
                                        </div></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
        
    </div>

    <div class="row" *ngIf="trendingMovie">
        <div class="container">
            <div class="row sect">
                <h1>Trending Movies</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of trendingMovieFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    
                                        <a routerLink="./watch/movie/{{trendingMovieIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{trendingMovieTitles[i][j]}}</p>
                                        </div></a>
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
        
    </div>

    <div class="row" *ngIf="popTV">
        <div class="container">
            <div class="row sect">
                <h1>Popular TV Shows</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of popTvFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    
                                        <a routerLink="./watch/tv/{{popTvIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{popTvTitles[i][j]}}</p>
                                        </div></a>
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
        
    </div>

    <div class="row" *ngIf="topTV">
        <div class="container">
            <div class="row sect">
                <h1>Top TV Shows</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of topTvFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    
                                        <a routerLink="./watch/tv/{{topTvIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{topTvTitles[i][j]}}</p>
                                        </div></a>
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
        
    </div>

    <div class="row" *ngIf="trendingTV">
        <div class="container">
            <div class="row sect">
                <h1>Trending TV Shows</h1>
            </div>
            <div class="row">
                <ngb-carousel class="text-left" *ngIf="!mobile" data-interval="false">

                    <ng-template ngbSlide *ngFor="let group of trendingTVFormatted; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of group; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    
                                        <a routerLink="./watch/tv/{{trendingTVIds[i][j]}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{trendingTVTitles[i][j]}}</p>
                                        </div></a>
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                    
                </ngb-carousel>
            </div>
        </div>
        
    </div>


</div>
  