import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  hostURL = "https://my-project-007-007.wl.r.appspot.com/";
  // http://localhost:8080

  constructor(private httpClient: HttpClient) { }

  getAllPost() {
    let URL = this.hostURL+ '/apis/posts';
    return this.httpClient.get(URL);
  }

  getPost(id: string) {
    let URL = this.hostURL+ '/apis/posts/' + id;
    return this.httpClient.get(URL);
  }

  getPostNowPlayingMovie() {
    let URL = this.hostURL+ '/apis/posts/now-playing';
    return this.httpClient.get(URL);
  }

  getPostPopular(media: String) {
    let URL = this.hostURL+ '/apis/posts/popular/' + media;
    return this.httpClient.get(URL);
  }

  getPostTop(media: String) {
    let URL = this.hostURL+ '/apis/posts/top/' + media;
    return this.httpClient.get(URL);
  }

  getPostTrending(media: String) {
    let URL = this.hostURL+ '/apis/posts/trending/' + media;
    return this.httpClient.get(URL);
  }

  getPostVideo(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/' + media + '/video/' + id;
    return this.httpClient.get(URL);
  }

  getPostDetail(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/detail/' + media + '/' + id;
    return this.httpClient.get(URL);
  }

  getPostCast(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/cast/' + media + '/' + id;
    return this.httpClient.get(URL);
  }

  getPostReview(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/review/' + media + '/' + id;
    return this.httpClient.get(URL);
  }

  getPostRecommend(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/recommend/' + media + '/' + id;
    return this.httpClient.get(URL);
  }

  getPostSimilar(id: String, media: String) {
    let URL = this.hostURL+ '/apis/posts/similar/' + media + '/' + id;
    return this.httpClient.get(URL);
  }

  getPostCastPerson(id: String) {
    let URL = this.hostURL+ '/apis/posts/person/' + id;
    return this.httpClient.get(URL);
  }

  getPostCastPersonEx(id: String) {
    let URL = this.hostURL+ '/apis/posts/external/' + id;
    return this.httpClient.get(URL);
  }

  getQuery(query: string) {
    let URL = this.hostURL+ '/apis/posts/search/' + query;
    // return this.httpClient.get(URL);
    return this.httpClient
      .get<[any, string[]]>(URL).pipe(
        map(response => response)
      );
  }
}

