<!-- <h1>{{title}}</h1>
<nav>
    <a routerLink="/">Homepage</a><br>
    <a routerLink="/posts">Posts</a><br>
    <a routerLink="/posts/12">Post Details</a>
</nav> -->
<!-- <link rel="stylesheet" href="https://cdn.staticfile.org/twitter-bootstrap/3.3.7/css/bootstrap.min.css">
	<script src="https://cdn.staticfile.org/jquery/2.1.1/jquery.min.js"></script>
	<script src="https://cdn.staticfile.org/twitter-bootstrap/3.3.7/js/bootstrap.min.js"></script> -->
<!-- A grey horizontal navbar that becomes vertical on small screens -->
<nav class="navbar navbar-expand-sm bg-dark navbar-dark navbar-custom" role="navigation">
<!-- <nav class="navbar navbar-default bg-dark navbar-dark" role="navigation"> -->
    <div class="container-fluid"> 
    <!-- Links -->
    <ul class="nav navbar-nav navbar-left">
        <li class="nav-item">
            <a class="navbar-brand" routerLink="/">USC Films</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/">Home</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/mylist">MyList</a>
        </li>
    </ul>
    
    <ul class="nav navbar-nav navbar-right">
        <li>
            
            <!-- <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"/> -->
            <!-- <pre>Model: {{ model | json }}</pre> -->
            <fieldset class="form-inline">
                <div class="form-group">
                    <ng-template #rt let-r="result" let-t="term" style="background-color: #040b47;">
                        <!-- <div class="dropdown-things"> -->
                            <a routerLink="./watch/{{r.media_type}}/{{r.id}}" rel="nofollow noopener noreferrer" style="color: white;">
                                <img [src]="'https://image.tmdb.org/t/p/w500/' + r['backdrop_path']" class="mr-1" style="width: 70px">
                                
                                <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
                            </a>
                        <!-- </div> -->
                    </ng-template>
                  <!-- <label for="typeahead-http">Search for a wiki page:</label> -->
                  <input id="typeahead-http" type="text" class="form-control mx-sm-3 bg-transparent text-light bg-dark border-top-0 border-right-0 border-left-0 rounded-0" [class.is-invalid]="searchFailed" [(ngModel)]="model" [ngbTypeahead]="search" placeholder="Search" [resultTemplate]="rt" [inputFormatter]="formatter"/>
                  <!-- <small *ngIf="searching" class="form-text text-muted">searching...</small> -->
                  <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
                </div>
            </fieldset>
            
            <!-- <pre>Model: {{ model | json }}</pre> -->
        </li>
    </ul>

    </div>

</nav>
<br>
<br>