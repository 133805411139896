import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-commonpage',
  templateUrl: './commonpage.component.html',
  styleUrls: ['./commonpage.component.css']
})
export class CommonpageComponent implements OnInit {
  @Input() dataReceive: String =  "DefaultValue!";
  constructor() { }

  ngOnInit(): void {
  }

}
