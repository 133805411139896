import { Component, OnInit } from '@angular/core';
import { PostsService} from '../../services/posts.service';

@Component({
  selector: 'app-firstpage',
  templateUrl: './firstpage.component.html',
  styleUrls: ['./firstpage.component.css']
})
export class FirstpageComponent implements OnInit {

  public message: String = "Passing the data!";
  public posts: any = {};
  public ids = [];
  public isEmpty: any;
  public medias: any = [];
  public posters: any = [];
  public titles: any = [];

  public myStorage = window.localStorage;

  constructor(private postService: PostsService) { }

  ngOnInit(): void {
    // this.fetchData();
    var j = -1, num = 0;
    var idList = JSON.parse(this.myStorage.getItem("order"));
    console.log(idList);

    idList.reverse();
    for(var i = 0; i < idList.length; i++) {
      if (num % 6 == 0) {
        j++;
        this.ids[j] = [];
        this.ids[j].push(idList[i])
        this.medias[j] = [];
        this.medias[j].push(String(this.myStorage.getItem(String(idList[i]))));
        this.posters[j] = [];
        this.posters[j].push(String(this.myStorage.getItem(String("path" + idList[i]))));
        this.titles[j] = [];
        this.titles[j].push(String(this.myStorage.getItem(String("title" + idList[i]))));
      }
      else {
        this.ids[j].push(idList[i])
        this.medias[j].push(String(this.myStorage.getItem(String(idList[i]))));
        this.posters[j].push(String(this.myStorage.getItem(String("path" + idList[i]))));
        this.titles[j].push(String(this.myStorage.getItem(String("title" + idList[i]))));
      }
      
      num++;
      // tmp = this.myStorage.getItem(this.ids[i]);
      // console.log(this.myStorage.getItem(this.ids[i]));
    }
    if (idList.length == 0) {
      this.isEmpty = true;
    }
    else {
      this.isEmpty = false;
    }
    
  }

  fetchData() {
    this.postService.getAllPost().subscribe(res => {
      this.posts = res;
    })
  }

}
