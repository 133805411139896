import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { FirstpageComponent } from './components/firstpage/firstpage.component';
import { SecondpageComponent } from './components/secondpage/secondpage.component';
import { FirstChildComponent } from './components/first-child/first-child.component';
import { SecondChildComponent } from './components/second-child/second-child.component';

const routes: Routes = [
    {path: '', component: HomepageComponent},
    {path: 'mylist', component: FirstpageComponent},
    {
        path: 'watch', 
        children: [
            {
                path: ':media',
                children: [
                    { path: ':id', component: FirstChildComponent}
                ]
            },
            // {
            //     path: 'tv',
            //     children: [
            //         { path: ':id', component: SecondChildComponent}
            //     ]
            // },
            // { path:'', component: FirstpageComponent},
            // { path: ':id', component: FirstChildComponent}, 
        ]
    },
    {path: 'second', component: SecondpageComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
