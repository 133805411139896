import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { PostsService} from '../../services/posts.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  public message: String = "Passing the data!";
  public posts: any = {};
  public mobile: any;
  public post: any = {};

  constructor(private postService: PostsService) { }

  public model: any;
  public img_path: any = [];

  paths:string[] = new Array(5);
  titles: string[] = new Array(5);
  ids: string[] = new Array(5);

  public myStorage = window.localStorage;
  isEmpty = true;
  public Cids: any = [];
  public Cmedias: any = [];
  public Cposters: any = [];
  public Ctitles: any = [];
  contiId = [];


  ngOnInit(): void {
    this.mobile = false;
    if (window.screen.width === 360) { // 768px portrait
      this.mobile = true;
    }
    
    this.fetchData();
    this.getNowPlaying();
    this.fetchData2();

    var j = -1, num = 0;
    var idList = JSON.parse(this.myStorage.getItem("order"));
    console.log(idList);
    idList.reverse();

    for(var i = 0; i < idList.length; i++) {
      if (num % 6 == 0) {
        j++;
        // this.contiId[j] = [];
        // this.Cids[j] = [];
        // console.log(this.Cids[0]);
        // this.Cids[j].push(idList[i]);
        // this.Cmedias[j] = [];
        // this.Cmedias[j].push(String(this.myStorage.getItem(String(idList[i]))));
        // this.Cposters[j] = [];
        // this.Cposters[j].push(String(this.myStorage.getItem(String("path" + idList[i]))));
        // this.Ctitles[j] = [];
        // this.Ctitles[j].push(String(this.myStorage.getItem(String("title" + idList[i]))));
      }
      else {
        // this.Cids[j].push(idList[i])
        // this.Cmedias[j].push(String(this.myStorage.getItem(String(idList[i]))));
        // this.Cposters[j].push(String(this.myStorage.getItem(String("path" + idList[i]))));
        // this.Ctitles[j].push(String(this.myStorage.getItem(String("title" + idList[i]))));
      }
      
      num++;
    }
    if (idList.length == 0) {
      this.isEmpty = true;
    }
    else {
      this.isEmpty = false;
    }
  }

  fetchData() {
    this.postService.getPostNowPlayingMovie().subscribe(res => {
      this.posts = res;
      // console.log(res);
      // console.log(this.posts["results"][0]["backdrop_path"]);
      
      for (var i = 0; i < 5; i++) {
        
        if (this.posts["results"][i]["backdrop_path"] != null) {
          this.paths[i] = "https://image.tmdb.org/t/p/original" + this.posts["results"][i]["backdrop_path"];
          this.titles[i] = this.posts["results"][i]["title"];
          this.ids[i] = this.posts["results"][i]["id"];
          // console.log(this.paths[i]);
        }
        
      }
    })
  }

  images = this.paths;

  getNowPlaying() {
    // var paths:string[] = new Array(10);
    // console.log(this.posts["results"]);
    // for (var i = 0; i < 5; i++) {
      
    //   paths.push(this.posts["results"][0]["backdrop_path"]);
    //   console.log(paths.length);
    // }
    console.log(this.paths);
  }



  // images = [0, 1, 2, 3, 4].map((n) => `https://picsum.photos/id/${n}/900/500`);
  
  
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  moviesFormatted = [];
  movieTitles = [];
  movieIds = []

  setInfo(post): void {
    var j = -1;

    for (var i = 0; i < this.post.length; i++) {
        if (i % 6 == 0) {
            j++;
            this.moviesFormatted[j] = [];
            this.moviesFormatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
            this.movieTitles[j] = [];
            this.movieTitles[j].push(post[i]["title"]);
            this.movieIds[j] = [];
            this.movieIds[j].push(post[i]["id"]);
        }
        else {
            this.moviesFormatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
            this.movieTitles[j].push(post[i]["title"]);
            this.movieIds[j].push(post[i]["id"]);
        }
    }
    // console.log(this.moviesFormatted);
  } 

  

  fetchData2() {
    this.postService.getPostPopular("movie").subscribe(res => {
      this.post = res["results"];
      console.log(this.post);
      this.setInfo(this.post);
    })

    this.postService.getPostPopular("tv").subscribe(res => {
      this.popTV = res["results"];
      this.setInfoNew(this.popTV, "tv", this.popTvFormatted, this.popTvTitles, this.popTvIds);
      console.log(this.popTvIds);
    })

    this.postService.getPostTop("movie").subscribe(res => {
      this.topMovie = res["results"];
      this.setInfoNew(this.topMovie, "movie", this.topMovieFormatted, this.topMovieTitles, this.topMovieIds);
      console.log(this.topMovieIds)
    })

    this.postService.getPostTrending("movie").subscribe(res => {
      this.trendingMovie = res["results"];
      this.setInfoNew(this.trendingMovie, "movie", this.trendingMovieFormatted, this.trendingMovieTitles, this.trendingMovieIds);
    })

    this.postService.getPostTop("tv").subscribe(res => {
      this.topTV = res["results"];
      this.setInfoNew(this.topTV, "tv", this.topTvFormatted, this.topTvTitles, this.topTvIds);
      console.log(this.topTvIds);
    })

    this.postService.getPostTrending("tv").subscribe(res => {
      this.trendingTV = res["results"];
      this.setInfoNew(this.trendingTV, "tv", this.trendingTVFormatted, this.trendingTVTitles, this.trendingTVIds);
      console.log(this.trendingTVIds);
    })
  }

  public popTV: any;
  popTvFormatted = [];
  popTvTitles = [];
  popTvIds = []

  public topTV: any;
  topTvFormatted = [];
  topTvTitles = [];
  topTvIds = []

  public trendingTV: any;
  trendingTVFormatted = [];
  trendingTVTitles = [];
  trendingTVIds = []

  topMovie: any;
  topMovieFormatted = [];
  topMovieTitles = [];
  topMovieIds = []

  trendingMovie: any;
  trendingMovieFormatted = [];
  trendingMovieTitles = [];
  trendingMovieIds = []

  setInfoNew(post, media, formatted, t, id): void {
    var j = -1;

    for (var i = 0; i < this.post.length; i++) {
        if (i % 6 == 0) {
            j++;
            formatted[j] = [];
            formatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
            t[j] = [];
            if (media == "movie") {
              t[j].push(post[i]["title"]);
            }
            else {
              t[j].push(post[i]["name"]);
            }
            id[j] = [];
            id[j].push(post[i]["id"]);
        }
        else {
            formatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
            if (media == "movie") {
              t[j].push(post[i]["title"]);
            }
            else {
              t[j].push(post[i]["name"]);
            }
            id[j].push(post[i]["id"]);
        }
    }
  } 

}
