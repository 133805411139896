<!-- <p>firstpage works!</p> -->
<!-- TODO we can display all the posts here! -->
<!-- <app-commonpage [dataReceive] = "message"></app-commonpage>
<app-commonpage [dataReceive] = "'This is another way!'"></app-commonpage>
<div>Hello world</div> -->

<!-- <h1>My WatchList</h1> -->
<!-- <div *ngIf = "posts">
    <div *ngFor = "let post of posts">
        <a [routerLink] = "[post.id]" >id: {{post.id}} <br></a>
        
        title: {{post.title}} <br> 
        userId: {{post.userId}} <br>
        body: {{post.body}} <br><br>
    </div>
</div> -->

<div class="container">
    <h1 *ngIf="isEmpty" class="text-center" style="color: white;">
        No items found in Watchlist.
    </h1>
    <div *ngIf = "!isEmpty">
        <h1 style="color: white;">
            My WatchList
        </h1>
        <div class="row" *ngFor="let group of ids; index as j" style="padding-top: 5%;">
            <div *ngFor = "let id of group; index as i" class="col-2 text-center" style="padding: 0%;">
                
                <a routerLink="../watch/{{medias[j][i]}}/{{id}}">
                    <img class="img-responsive text-center align-self-center" src="https://image.tmdb.org/t/p/w500/{{ posters[j][i] }}" width="75%" style="margin:0;">
                </a>
            </div>
            <br><br>
        </div>

    </div>
</div>

