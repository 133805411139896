<div>
  <app-nav-bar></app-nav-bar>
    <router-outlet></router-outlet>
    <!-- <app-homepage></app-homepage> -->
    <!-- <app-firstpage></app-firstpage> -->
    <app-secondpage></app-secondpage>
    <br><br><br>
  <div class="fixed-bottom" >
    <app-footerpage style="color: white; background-color: #03061c; position: absolute; bottom: 0;margin: auto;left: 0;right: 0;"></app-footerpage>
  </div>
  
</div>