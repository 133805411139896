import { Component, OnInit, Injectable } from '@angular/core';
import { HttpParams} from '@angular/common/http';
import {Observable, of, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import { PostsService} from '../../services/posts.service';

// const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable()
export class WikipediaService {
  constructor(private postService: PostsService) {}
  // constructor(private http: HttpClient) {}
  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.postService.getQuery(term);
    // this.postService.getQuery(term).subscribe(res => {
    //   console.log(res);
    //   return res;
    // })

    // return this.http
    //   .get<[any, string[]]>(WIKI_URL, {params: PARAMS.set('search', term)}).pipe(
    //     map(response => response[1])
    //   );
  }
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  providers: [WikipediaService],
  styleUrls: ['./nav-bar.component.css']
})


export class NavBarComponent{

  ngOnInit(): void {
  }

  model: any;
  searching = false;
  searchFailed = false;

  constructor(private _service: WikipediaService) {}

  search: OperatorFunction<string, readonly {title, backdrop_path, id}[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>

        this._service.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

    formatter = [(x: {title: string}) => x.title, (x: {backdrop_path: string}) => x.backdrop_path, (x: {id: string}) => x.id, (x: {media_type: String}) => x.media_type];

}
