<!-- <p>first-child works!</p> -->
<!-- {{ category }} -->
<!-- TODO here we want to display all the details of the post. -->

<div id="fb-root"></div>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v10.0" nonce="O6Av9GGs"></script>

<div *ngIf="detail">
    <!-- id: {{post.id}} <br> -->
    <!-- title: {{post.name}} <br> 
    userId: {{post.key}} <br> -->
    <!-- body: {{post.body}} <br><br> -->

    <div class="container">
        <div class="row">
            <div class="col">
                <youtube-player
                    videoId={{post.key}}
                    suggestedQuality="highres"
                    [width]="780"
                    [startSeconds]="0"
                    [endSeconds]="60">
                </youtube-player>
            </div>
            <div class="col">
                <h1 class="title">
                    {{detail.title}}
                </h1>
                <h3 class="tagline">
                    {{detail.tagline}}
                </h3>
                <p class="rating">
                    {{detail.release_year}} | &#9733; {{detail.vote_average}} | {{detail.runtimehour}}hrs {{detail.runtime}}mins
                </p>
                <br>
                <div *ngIf="isGenres" class="genres">
                    Genres: {{detail.genre_names}}
                </div>
                <br>
                <div *ngIf="isLanguage" class="genres" >
                    Spoken Languages: <p class="text1">{{detail.languages}}</p>
                </div>
                <br><br>
                <!-- <script>
                    function clickCounter() {
                      this.myStorage.setItem(this.id, this.detail);
                      document.getElementById("testttt").innerHTML = "111";
                    }
                </script> -->
            <button class="btn btn-primary" (click)="addWatchList()" id="addListButton" type="button">{{ buttonText }}</button>
                
            <br><br>

                <ngb-alert #selfClosingAlert *ngIf="successMessage" type="{{alertType}}" (closed)="successMessage = ''">{{ successMessage }}
                </ngb-alert>

            </div>
        </div>

        <br>
        <div class="row">
            <div class="text2 col-12">
                <h4 style="color: white; font-size: 18;">Description</h4>
                {{detail.overview}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="text2 col-12">
                <h4 style="color: white;">Share</h4>

                <div style="float: inline-start; width: 100; transform: 0.8;">
                
                
                    <a class="twitter-share-button fa-lg" style="float: inline-start;" target="_blank"
                        href="https://twitter.com/intent/tweet?text=Watch%20{{detail.title}}%20https://www.youtube.com/watch?v={{post.key}}%20%23USC%20%23CSCI571%20%23FightOn"
                        data-size="large">
                    <fa-icon [icon]="faTwitter" style="width: 20;"></fa-icon></a>

                    <!-- <div class="fb-share-button" style="float: inline-start;" data-href="https://www.youtube.com/watch?v={{post.key}}" data-layout="button_count" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D{{post.key}}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">
                    
                    <fa-icon [icon]="faFacebookSquare"></fa-icon></a></div> -->
                    &nbsp;&nbsp;
                    <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D{{post.key}}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore fa-lg">
                    
                        <fa-icon [icon]="faFacebookSquare" style="color: #4267B2;"></fa-icon></a>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            
            <div class="text2 col-12" style="max-width: 100%;">
                <h2>
                    Full Cast and Crew
                </h2>
                <div class="col-12">
                    <div class="cast container-fluid" style="padding: 0;">
                        <!-- <div class="row d-flex flex-row flex-nowrap overflow-auto row-horizon"> -->
                            <div class="row flex-nowrap">
                            <div class="card col-2" style="padding: 0;" *ngFor="let cast of castInfo">
                                <div class="card btn" (click)="openLg(content, cast.id)">
                                    <img src="{{cast.profile_path}}">
                                    <p class="text3 text-center">
                                        {{ cast.name}}
                                    </p>
                                    <p class="text3 text-center">
                                        AS
                                    </p>
                                    <p class="text3 text-center" style="font-weight: 300;">
                                        {{cast.character}}
                                    </p>
                                    
        
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #content let-modal >
                    <div class="modal-header">
                      <h4 class="modal-title">{{ person.name }}</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-3">
                                    <img src="{{person.profile_path}}" style="width: 80%;">
                                </div>
                                <div class="col-9">
                                    <p *ngIf="person.birthday">Birth: {{ person.birthday}}</p>
                                    <p *ngIf="person.place_of_birth">Birth Place: {{ person.place_of_birth}}</p>
                                    <p *ngIf="person.gender">Gender: {{ person.gender}}</p>
                                    <p *ngIf="person.homepage">Website: <a target="_blank" href="{{person.homepage}}">{{person.homepage}}</a></p>
                                    <p *ngIf="person.known_for_department">Known for: {{ person.known_for_department}}</p>
                                    <p *ngIf="person.also_known">Also Known as: {{ person.also_known}}</p>
                                    <div>
                                        <a class="fa-lg" placement="top" ngbTooltip="Visit IMDB" *ngIf="personEx.imdb_id" target="_blank" href="https://imdb.com/name/{{personEx.imdb_id}}"><fa-icon [icon]="faImdb" style="width: 20; color: #DBA506;"></fa-icon></a>
                                        &nbsp;

                                        <a class="fa-lg" placement="top" ngbTooltip="Visit Instagram" *ngIf="personEx.instagram_id" target="_blank" href="https://instagram.com/{{personEx.instagram_id}}"><fa-icon [icon]="faIns" style="width: 20; color: #C13584;"></fa-icon></a>
                                        &nbsp;

                                        <a target="_blank" placement="top" ngbTooltip="Visit Facebook" *ngIf="personEx.facebook_id" href="https://www.facebook.com/{{personEx.facebook_id}}" class="fb-xfbml-parse-ignore fa-lg">
                                            <fa-icon [icon]="faFacebookSquare" style="color: #4267B2;"></fa-icon></a>
                                        &nbsp;
                                        <a class="twitter-share-button fa-lg" placement="top" ngbTooltip="Visit Twitter" *ngIf="personEx.twitter_id" style="float: inline-start;" target="_blank"
                                        href="https://twitter.com/{{personEx.twitter_id}}"
                                        data-size="large">
                                        <fa-icon [icon]="faTwitter" style="width: 20;"></fa-icon></a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3> Biography</h3>
                                    <p>{{ person.biography }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
                    </div> -->
                  </ng-template>

            </div>
        </div>    

        <br>

        <div class="row">
            <div class="col-12">
                <h2>
                    Reviews &nbsp; {{reviewNum}}
                </h2>
                <div class="col-12" style="padding: 0;" *ngFor="let review of reviewList; index as i">
                    <div class="container" style="padding: 0;">
                        <div class="row" style="background-color: white; margin: 20px 20px 0px 0px; height: 50%; border-radius: 10px;">
                            <div class="col-2 text-center">
                                <img src="{{reviews[i].author_details.avatar_path}}" style="width: 70%; border-radius: 60%;">
                            </div>
                            <div class="col-10 reviewCon">
                                <h4>A review created by {{ reviews[i].author }}</h4>
                                <br>
                                <p style="color: rgba(94, 94, 94, 0.596); font-weight: 300;"> Written by {{ reviews[i].author }} on {{ reviews[i].created_at}}</p>
                                <p class="review-content"> {{reviews[i].content}} </p>
                                <a target="_blank" href="{{reviews[i].url}}">Read the rest</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <br><br>
        <div class="row">
            <div class="rol-12">
                <h2>
                    Recommended {{mediaStr}}
                </h2>
            </div>
            <div class="rol-12">
                <ngb-carousel class="text-left" data-interval="false">

                    <ng-template ngbSlide *ngFor="let recGroup of recommendList; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of recGroup; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    <!-- <div class="picsum-img-wrapper align-self-bottom text-bottom"> -->
                                        <a routerLink="../../{{media}}/{{game.id}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game.poster_path}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{game.title}}</p>
                                        </div></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                
                </ngb-carousel>       
            </div>

            
        </div>

        <br><br>
        <div class="row">
            <div class="rol-12">
                <h2>
                    Similar {{mediaStr}}
                </h2>
            </div>
            <div class="rol-12">
                <ngb-carousel class="text-left" data-interval="false">

                    <ng-template ngbSlide *ngFor="let simGroup of similarList; index as i" width="80%" [interval]="false" >
                        
                        <div class="container text-left">
                            <div class="col-12">
                            
                            <div class="row justify-content-start">
                                <div class="col-2 align-self-center img-s" *ngFor="let game of simGroup; index as j" style="display: inline-block; margin:0; padding: 0;" >
                                    <!-- <div class="picsum-img-wrapper align-self-bottom text-bottom"> -->
                                        <a routerLink="../../{{media}}/{{game.id}}" rel="nofollow noopener noreferrer">
                                        <img class="img-responsive text-center align-self-center" src="{{game.poster_path}}" width="90%" style="margin:0;">
                                        <div class="carousel-caption text-center" style="padding: 0; bottom: 0; left:0; right: 0;">
                                            <p>{{game.title}}</p>
                                        </div></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                            </div>
                        
                        </div>
                    </ng-template>
                
                </ngb-carousel>       
            </div>

            
        </div>

    </div>



</div>

