import { Component, OnInit, ViewChild, ViewEncapsulation, OnChanges, Input, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostsService} from '../../services/posts.service';
import { faTwitter, faFacebookSquare, faImdb, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
    type: 'success',
    message: 'This is an success alert',
  }, {
    type: 'info',
    message: 'This is an info alert',
  }]

@Component({
  selector: 'app-first-child',
  templateUrl: './first-child.component.html',
  styleUrls: ['./first-child.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FirstChildComponent implements OnInit {
  @Input() p = this.route.snapshot.params;

  public id:any;
  public media: any;
  public post: any;
  public detail: any;
  public isGenres: any;
  public isLanguage: any;
  public existed: any;
  public castInfo: any;
  public reviews: any;
  public reviewList: any;
  public reviewNum: Number;
  public recommends: any;
  public similars: any;
  mediaStr = "Movies";
  faTwitter = faTwitter;
  faFacebookSquare = faFacebookSquare;
  faImdb = faImdb;
  faIns = faInstagram;
  
  constructor(private route: ActivatedRoute, private postService: PostsService, private modalService: NgbModal, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; 
   }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.media = this.route.snapshot.paramMap.get('media');
    if (this.media == "tv") {
      this.mediaStr = "TV shows";
    }
    this.fetchData();

    // Change order in storage
    if (this.myStorage.getItem("order") == null) {
      this.myStorage.setItem("order", JSON.stringify(this.idListStorage));
    }
    this.idListStorage = JSON.parse(this.myStorage.getItem("order"));
    if (this.idListStorage.length != 0) {
      if (String(this.myStorage.getItem(this.id)) == this.media) {
        this.existed = true;
        for (let i = this.idListStorage.indexOf(this.id); i < this.idListStorage.length - 1; i++) {
          this.idListStorage[i] = this.idListStorage[i+1];
        }
        this.idListStorage.pop();
        this.idListStorage.push(String(this.id));
        this.myStorage.setItem("order", JSON.stringify(this.idListStorage));
      }
    }


    // youtube
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    // alert
    this._success.subscribe(message => this.successMessage = message);
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this._danger.subscribe(message => this.successMessage = message);
    this._danger.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    // button
    if (this.myStorage.getItem(this.id) == null) {
      this.buttonText = "Add to Watchlist";
    }
    else {
      this.buttonText = "Remove from Watchlist";
    }
    

  }

  recommendList = [];
  similarList = [];

  fetchData() {
    this.postService.getPostVideo(this.id, this.media).subscribe(res => {
      if (res == null) {
        this.post = {};
        this.post["key"] = "tzkWB85ULJY";
      }
      else this.post = res;
      console.log(res);
      
      // if (this.post["key"].length == 0) res["key"] = "tzkWB85ULJY";
    })

    this.postService.getPostDetail(this.id, this.media).subscribe(res => {
      this.detail = res;
      this.isLanguage = true;
      if (this.detail["languages"].length == 0) {
        this.isLanguage = false;
      }
      this.isGenres = true;
      if (this.detail["genre_names"].length == 0) {
        this.isGenres = false;
      }
    })
    this.postService.getPostCast(this.id, this.media).subscribe(res => {
      console.log(res);
      this.castInfo = res;
      for (var i = 0; i < this.castInfo.length; i++) {
        if (this.castInfo[i]["profile_path"] == null) {
          this.castInfo[i]["profile_path"] = "https://bytes.usc.edu/cs571/s21_JSwasm00/hw/HW6/imgs/person-placeholder.png";
        }
        else {
          this.castInfo[i]["profile_path"] = "https://image.tmdb.org/t/p/w185" + this.castInfo[i]["profile_path"];
        }
      }
    })

    this.postService.getPostRecommend(this.id, this.media).subscribe(res => {
      console.log(res);
      this.recommends = res;
      for (var i = 0; i < this.recommends.length; i++) {
        if (this.recommends[i]["poster_path"] == null) {
          this.recommends[i]["poster_path"] = "https://cinemaone.net/images/movie_placeholder.png";
        }
        else {
          this.recommends[i]["poster_path"] = "https://image.tmdb.org/t/p/w500" + this.recommends[i]["poster_path"];
        }
      }
      var j = -1;
      for (var i = 0; i < this.recommends.length; i++) {
        if (i % 6 == 0) {
          j++;
          this.recommendList[j] = [];
          this.recommendList[j].push(this.recommends[i]);
        }
        else {
          this.recommendList[j].push(this.recommends[i]);
        }

      }
    })

    this.postService.getPostSimilar(this.id, this.media).subscribe(res => {
      console.log(res);
      this.similars = res;
      for (var i = 0; i < this.similars.length; i++) {
        if (this.similars[i]["poster_path"] == null) {
          this.similars[i]["poster_path"] = "https://cinemaone.net/images/movie_placeholder.png";
        }
        else {
          this.similars[i]["poster_path"] = "https://image.tmdb.org/t/p/w500" + this.similars[i]["poster_path"];
        }
      }
      var j = -1;
      for (var i = 0; i < this.similars.length; i++) {
        if (i % 6 == 0) {
          j++;
          this.similarList[j] = [];
          this.similarList[j].push(this.similars[i]);
        }
        else {
          this.similarList[j].push(this.similars[i]);
        }

      }
    })

    this.postService.getPostReview(this.id, this.media).subscribe(res => {
      var num = 0;
      this.reviewNum = 0;
      this.reviews = res;
      this.reviewList = [];
      console.log(this.reviews)
      for (var i = 0; i < this.reviews.length; i++) {
        this.reviewList.push(this.reviews[i]);
        if (this.reviews[i]["author_details"]["avatar_path"] == null) {
          this.reviews[i]["author_details"]["avatar_path"] = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHnPmUvFLjjmoYWAbLTEmLLIRCPpV_OgxCVA&usqp=CAU";

        }
        else if (this.reviews[i]["author_details"]["avatar_path"].indexOf("http") == -1) {
          this.reviews[i]["author_details"]["avatar_path"] = "https://image.tmdb.org/t/p/original" + this.reviews[i]["author_details"]["avatar_path"];
        }
        else {
          this.reviews[i]["author_details"]["avatar_path"] = this.reviews[i]["author_details"]["avatar_path"].substr(1);
        }
        if (this.reviews[i]["created_at"] != null) {
          let tmp = new Date(this.reviews[i]["created_at"])
          this.reviews[i]["created_at"] = tmp.getMonth() + " " + tmp.getDate() + ", " + tmp.getFullYear() + ", ";
          var h = Number(tmp.getHours());
          let pm = false;
          if ( h > 12) {
            h -= 12;
            pm = true;
          }
          if (pm) {
            this.reviews[i]["created_at"] += String(h) + ":" + tmp.getMinutes() + ":" + tmp.getSeconds() + " PM";
          }
          else {
            this.reviews[i]["created_at"] += String(h) + ":" + tmp.getMinutes() + ":" + tmp.getSeconds() + " AM";
          }
        }
        num++;
        if (num >= 10) break;
      }
      this.reviewNum = num;
      
    })

  }

  public myStorage = window.localStorage;
  idListStorage = [];
  public test: any;
  public buttonText: String;
  public addWatchList() {
    if (this.myStorage.getItem(this.id) == null) {
      this.idListStorage = JSON.parse(this.myStorage.getItem("order"));
      this.idListStorage.push(this.id);
      this.myStorage.setItem("order", JSON.stringify(this.idListStorage));

      this.myStorage.setItem(this.id, this.media);
      this.myStorage.setItem("path" + this.id, this.detail["poster_path"]);
      this.myStorage.setItem("title" + this.id, this.detail["title"]);

      this.test = this.myStorage.key(0);
      console.log(String(this.myStorage.getItem("path"+this.id)));
      this.buttonText = "Remove from Watchlist";
      this.alertType = 'success';
      this._success.next(`Added to watchlist.`);
    }
    else {
      this.idListStorage = JSON.parse(this.myStorage.getItem("order"));
      for (let i = this.idListStorage.indexOf(this.id); i < this.idListStorage.length - 1; i++) {
        this.idListStorage[i] = this.idListStorage[i+1];
      }
      this.idListStorage.pop();
      this.myStorage.setItem("order", JSON.stringify(this.idListStorage));

      this.myStorage.removeItem(this.id);
      this.myStorage.removeItem("path" + this.id);
      this.myStorage.removeItem("title" + this.id);

      this.buttonText = "Add to Watchlist";
      this.alertType = 'danger';
      this._danger.next("Removed from watchlist.");
      
    }
    
    
  }


  public alertType = 'success';
  private _success = new Subject<string>();
  private _danger = new Subject<string>();

  staticAlertClosed = false;
  successMessage = '';

  @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;


  // cast
  closeResult: string;
  castID: String;
  public person: any;
  public personEx: any;
  openLg(content, cID) {
    this.castID = cID;
    this.postService.getPostCastPerson(this.castID).subscribe(res => {
      console.log(res);
      this.person = res;
      console.log(this.person["homepage"]);
      if (this.person["profile_path"] == null) {
        this.person["profile_path"] = "https://bytes.usc.edu/cs571/s21_JSwasm00/hw/HW6/imgs/person-placeholder.png";
      }
      else {
        this.person["profile_path"] = "https://image.tmdb.org/t/p/w185" + this.person["profile_path"];
      }

      if (this.person["gender"] != null) {
        if (this.person["gender"] == 1) {
          this.person["gender"] = "Female";
        }
        else if (this.person["gender"] == 2){
          this.person["gender"] = "Male";
        }
        else {
          this.person["gender"] = "Undefined";
        }
      }

      this.person["also_known"] = null;
      for (var i = 0; i < this.person["also_known_as"].length - 1; i++) {
        if (i == 0) this.person["also_known"] = "";
        this.person["also_known"] += this.person["also_known_as"][i] + ", ";
      }
      this.person["also_known"] += this.person["also_known_as"][this.person["also_known_as"].length - 1];

    })

    this.postService.getPostCastPersonEx(this.castID).subscribe(res => {
      console.log(res);
      this.personEx = res;

    })
    this.modalService.open(content, { size: 'lg' });
    
    

  }
}
