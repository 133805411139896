import { Component, OnInit } from '@angular/core';
import { PostsService} from '../../services/posts.service';

@Component({
  selector: 'app-secondpage',
  templateUrl: './secondpage.component.html',
  styleUrls: ['./secondpage.component.css']
})
export class SecondpageComponent implements OnInit {

  public mobile: any;
  public post: any;

  constructor(private postService: PostsService) { }

  ngOnInit(): void {
    // this.mobile = false;
    // if (window.screen.width === 360) { // 768px portrait
    //   this.mobile = true;
    // }
    // this.fetchData();
    
  }

  games = ["a", "b", "c", "d", "e"];
  moviesFormatted = [];

  setInfo(post): void {
    var j = -1;

    for (var i = 0; i < this.post.length; i++) {
        if (i % 6 == 0) {
            j++;
            this.moviesFormatted[j] = [];
            this.moviesFormatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
        }
        else {
            this.moviesFormatted[j].push("https://image.tmdb.org/t/p/w500"+post[i]["poster_path"]);
        }
    }
    console.log(this.moviesFormatted);
  } 
  fetchData() {
    this.postService.getPostPopular("movie").subscribe(res => {
      this.post = res["results"];
      console.log(this.post);
      this.setInfo(this.post);
    })
  }


  

}
